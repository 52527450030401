import React, { useState, useCallback } from "react";
import axios from "axios";
import "../../styles/EmailLogin.css";
import { useNavigate } from "react-router-dom";

const EmailLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_AUTH0_CLIENT_SECRET;

  // Login logic
  const handleLogin = useCallback(async () => {
    if (!email || !password) {
      setError("Please enter both email and password.");
      return;
    }

    try {
      const response = await axios.post(
        `https://${domain}/oauth/token`,
        {
          grant_type: "password",
          username: email,
          password,
          audience: `https://${domain}/api/v2/`,
          client_id: clientId,
          client_secret: clientSecret,
          scope: "openid profile email",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Store the token and redirect to profile
      localStorage.setItem("authToken", response.data.access_token);
      setSuccess(true);
      setError(null);
      // navigate("/profile");
      // navigate(`${process.env.WEB_PAGE_URL}`);
      // navigate("https://frontend-v2-7xt.pages.dev/");
      console.log("WEB_PAGE_URL:", process.env.REACT_APP_WEB_PAGE_URL);
       window.location.href = process.env.REACT_APP_WEB_PAGE_URL || "https://frontend-v2-7xt.pages.dev/";
    } catch (error) {
      console.error("Login failed:", error.response?.data || error.message);
      setError("Login failed. Please check your credentials and try again.");
    }
  }, [email, password, domain, clientId, clientSecret]);

  return (
    <div className="email-login-page">
      <div className="left-section">
        <img
          src={`${process.env.PUBLIC_URL}/images/Holiday_World_Login_Page.png`}
          alt="Holiday"
          className="holiday-image"
        />
      </div>
      <div className="login-section">
        <h1 className="logo">Holiday World</h1>
        <h2>Log in with Email</h2>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && (
          <p style={{ color: "green" }}>Login successful! Redirecting...</p>
        )}
        <form className="login-form">
          <label>Email Address</label>
          <input
            type="email"
            placeholder="yourname@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="button" onClick={handleLogin}>
            Log in
          </button>
          <p>
            Don't have an account?{" "}
            <span className="link" onClick={() => navigate("/signup")}>
              Join now
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default EmailLogin;
