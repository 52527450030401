import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./components/App";
import "@fortawesome/fontawesome-free/css/all.min.css";

const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: "localstorage",
};

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <BrowserRouter>
      <Auth0Provider {...auth0Config}>
        <App />
      </Auth0Provider>
    </BrowserRouter>
  );
} else {
  console.error(
    "Root element not found. Ensure there is a div with id 'root' in your HTML."
  );
}
