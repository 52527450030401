import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../styles/Profile.css";

const Profile = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Environment Variables
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_AUTH0_CLIENT_SECRET;
  const redirectUri = `${process.env.REACT_APP_BASE_URL}/profile`;

  // Fetch user profile using access token
  const fetchUserProfile = useCallback(
    async (token) => {
      try {
        const response = await axios.get(`https://${domain}/userinfo`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserProfile(response.data);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
        localStorage.removeItem("authToken");
        navigate("/");
      } finally {
        setLoading(false);
      }
    },
    [domain, navigate]
  );

  // Handle Google login using authorization code
  const retrieveTokenFromCode = useCallback(
    async (code) => {
      try {
        const response = await axios.post(
          `https://${domain}/oauth/token`,
          {
            grant_type: "authorization_code",
            client_id: clientId,
            client_secret: clientSecret,
            code,
            redirect_uri: redirectUri,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        const { access_token } = response.data;
        localStorage.setItem("authToken", access_token);
        fetchUserProfile(access_token);
      } catch (error) {
        console.error("Token exchange failed:", error);
        navigate("/");
      } finally {
        setLoading(false);
      }
    },
    [domain, clientId, clientSecret, redirectUri, fetchUserProfile, navigate]
  );

  // Handle direct token in URL fragment
  const retrieveTokenFromUrlFragment = useCallback(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.replace("#", "?"));
    const accessToken = params.get("access_token");

    if (accessToken) {
      localStorage.setItem("authToken", accessToken);
      fetchUserProfile(accessToken);
    } else {
      setLoading(false);
    }
  }, [fetchUserProfile]);

  // Determine authentication flow
  const handleAuthFlow = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const token = localStorage.getItem("authToken");

    if (code) {
      retrieveTokenFromCode(code); // Handle Google login with authorization code
    } else if (token) {
      fetchUserProfile(token); // Handle token-based login
    } else {
      retrieveTokenFromUrlFragment(); // Handle token in URL fragment
    }
  }, [retrieveTokenFromCode, fetchUserProfile, retrieveTokenFromUrlFragment]);

  useEffect(() => {
    handleAuthFlow();
  }, [handleAuthFlow]);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="profile-page">
      {userProfile ? (
        <div className="profile-card">
          <img
            src={userProfile.picture}
            alt="User profile"
            className="profile-picture"
            style={{ width: "100px", height: "100px", borderRadius: "50%" }}
          />
          <h1 className="welcome-message">
            Welcome, {userProfile.name || "User"}!
          </h1>
          <p className="user-info">
            Email: {userProfile.email || "Email not available"}
          </p>
          <button
            className="logout-button"
            onClick={() => {
              localStorage.removeItem("authToken");
              navigate("/");
            }}
          >
            Logout
          </button>
        </div>
      ) : (
        <p>You are not logged in.</p>
      )}
    </div>
  );
};

export default Profile;
