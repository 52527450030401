import React, { useState, useCallback } from "react";
import axios from "axios";
import "../../styles/EmailSignup.css";
import { useNavigate } from "react-router-dom";

const EmailSignup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const navigate = useNavigate();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const apiToken = process.env.REACT_APP_AUTH0_API_TOKEN;

  // Helper functions for validation
  const validatePassword = (value) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    if (value.length < minLength) {
      return "Password must be at least 8 characters long.";
    }
    if (!hasNumber.test(value)) {
      return "Password must include at least one number.";
    }
    if (!hasSpecialChar.test(value)) {
      return "Password must include at least one special character.";
    }
    return "";
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    return password === confirmPassword ? "" : "Passwords do not match.";
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email)
      ? ""
      : "Please enter a valid email address.";
  };

  // Signup logic
  const handleSignup = useCallback(async () => {
    const emailError = isValidEmail(email);
    if (emailError) {
      setError(emailError);
      return;
    }

    if (passwordError || confirmPasswordError) {
      setError("Please resolve all errors before signing up.");
      return;
    }

    try {
      const response = await axios.post(
        `https://${domain}/api/v2/users`,
        {
          email,
          password,
          connection: "Username-Password-Authentication",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      localStorage.setItem("refresh_token", response.data.refresh_token);
      setSuccess(true);
      setError(null);
      navigate("/");
    } catch (error) {
      if (error.response?.status === 409) {
        setError("This email is already registered. Please try logging in.");
      } else {
        setError("Signup failed. Please try again.");
      }
    }
  }, [
    email,
    password,
    domain,
    apiToken,
    passwordError,
    confirmPasswordError,
    navigate,
  ]);
  
  // Handlers for password and confirm password validation
  const handlePasswordChange = (value) => {
    setPassword(value);
    setPasswordError(validatePassword(value));
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    setConfirmPasswordError(validateConfirmPassword(password, value));
  };

  return (
    <div className="email-signup-page">
      <div className="left-section">
        <img
          src={`${process.env.PUBLIC_URL}/images/Holiday_World_SignUp_Page.png`}
          alt="Holiday"
          className="holiday-image"
        />
      </div>
      <div className="form-container">
        <h1 className="logo">Holiday World</h1>
        <h2>Sign up with Email</h2>
        &nbsp;
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && (
          <p style={{ color: "green" }}>
            Registration successful! Redirecting to login...
          </p>
        )}
        <form className="signup-form">
          <label>Email Address</label>
          <input
            type="email"
            placeholder="yourname@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label>Password</label>
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            required
          />
          {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}

          <label>Confirm Password</label>
          <input
            type="password"
            placeholder="Re-enter your password"
            value={confirmPassword}
            onChange={(e) => handleConfirmPasswordChange(e.target.value)}
            required
          />
          {confirmPasswordError && (
            <p style={{ color: "red" }}>{confirmPasswordError}</p>
          )}

          <button type="button" onClick={handleSignup}>
            SIGN UP
          </button>
        </form>
      </div>
    </div>
  );
};

export default EmailSignup;
