import React from "react";
import "../../styles/Login.css";
import { useNavigate } from "react-router-dom";

const generateAuthUrl = (connection, responseType = "token id_token") => {
  const nonce = Math.random().toString(36).substring(2, 15);
  const state = Math.random().toString(36).substring(2, 15);
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  //const redirectUri = `${process.env.REACT_APP_BASE_URL}/profile`;
  const redirectUri = `${process.env.REACT_APP_WEB_PAGE_URL}`;

  return `https://${domain}/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}&connection=${connection}&scope=openid%20profile%20email&response_type=${responseType}&nonce=${nonce}&state=${state}`;
};

const Login = () => {
  const navigate = useNavigate();

  const handleEmailLogin = () => navigate("/email-login");
  const handleFacebookLogin = () => {
    window.location.href = generateAuthUrl("facebook");
  };
  const handleGoogleLogin = () => {
    window.location.href = generateAuthUrl("google-oauth2", "code");
  };
  const redirectToSignup = () => navigate("/signup");

  return (
    <div className="login-page">
      <div className="left-section">
        <img
          src={`${process.env.PUBLIC_URL}/images/Holiday_World_Login_Page.png`}
          alt="Holiday"
          className="holiday-image"
        />
      </div>
      <div className="form-container">
        <h1 className="logo">Holiday World</h1>
        <h2
          style={{ textAlign: "left", marginLeft: "0", marginBottom: "20px" }}
        >
          Login
        </h2>
        &nbsp;
        <div className="login-buttons">
          <button
            className="login-btn email"
            onClick={handleEmailLogin}
            aria-label="Log in with Email"
          >
            <i className="fas fa-envelope"></i> Log in with Email
          </button>
          <button
            className="login-btn facebook"
            onClick={handleFacebookLogin}
            aria-label="Log in with Facebook"
          >
            <i className="fab fa-facebook-f"></i> Log in with Facebook
          </button>
          <button
            className="login-btn google"
            onClick={handleGoogleLogin}
            aria-label="Log in with Google"
          >
            <i className="fab fa-google"></i> Log in with Google
          </button>
        </div>
        <p className="signup-prompt">
          Don't have an account?{" "}
          <span className="link" onClick={redirectToSignup}>
            Join now
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
