import React from "react";
import "../../styles/Signup.css";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();

  // Environment Variables
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  // const redirectUri = `${process.env.REACT_APP_BASE_URL}/profile`;
  const redirectUri = `${process.env.REACT_APP_WEB_PAGE_URL}`;


  // Generate random nonce and state values
  const generateRandomString = () =>
    Math.random().toString(36).substring(2, 15);

  const createAuthUrl = (connection, responseType = "token id_token") => {
    const nonce = generateRandomString();
    const state = generateRandomString();
    return (
      `https://${domain}/authorize?` +
      `client_id=${clientId}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&connection=${connection}` +
      `&scope=openid%20profile%20email` +
      `&response_type=${responseType}` +
      `&nonce=${nonce}` +
      `&state=${state}`
    );
  };

  // Handlers for navigation and sign-ups
  const redirectToLogin = () => navigate("/");
  const handleEmailSignUp = () => navigate("/email-signup");

  const handleFacebookSignUp = () => {
    const facebookLoginUrl = createAuthUrl("facebook");
    window.location.href = facebookLoginUrl;
  };

  const handleGoogleSignUp = () => {
    const googleAuthUrl = createAuthUrl("google-oauth2", "code");
    window.location.href = googleAuthUrl;
  };

  return (
    <div className="sign-up-page">
      <div className="left-section">
        <img
          // src={`${process.env.PUBLIC_URL}/Holiday_image.png`}
          src={`${process.env.PUBLIC_URL}/images/Holiday_World_SignUp_Page.png`}
          alt="Holiday"
          className="holiday-image"
        />
      </div>
      <div className="form-container">
        <h1 className="logo">Holiday World</h1>
        <h2
          style={{ textAlign: "left", marginLeft: "0", marginBottom: "20px" }}
        >
          Create an Account
        </h2>
        &nbsp;
        <div className="signup-buttons">
          <button className="signup-btn email" onClick={handleEmailSignUp}>
            <i className="fas fa-envelope"></i> Sign up with Email
          </button>
          <button
            className="signup-btn facebook"
            onClick={handleFacebookSignUp}
          >
            <i className="fab fa-facebook-f"></i> Sign up with Facebook
          </button>
          <button className="signup-btn google" onClick={handleGoogleSignUp}>
            <i className="fab fa-google"></i> Sign up with Google
          </button>
        </div>
        <p className="login-prompt">
          Have an account?{" "}
          <span className="link" onClick={redirectToLogin}>
            Log in
          </span>
        </p>
        <p className="terms">
          By signing up, you agree to the <a href="/terms">Terms of Use</a> and{" "}
          <a href="/privacy">Privacy Policy</a>. You also consent to receiving
          electronic communications from Holiday World, including promotional
          and marketing content. U.S. residents will automatically opt into
          Holiday World email communication.
        </p>
      </div>
    </div>
  );
};

export default SignUp;